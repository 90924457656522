import styled from 'styled-components';
import { Icon as MatIcon,SvgIcon } from '@material-ui/core';

export const UiIcon = styled(MatIcon)`
	padding: 2px;
	font-size: ${({ fontSize }) => fontSize || '2rem'};
	color: ${({ icolor }) => icolor};
	border-radius: 50%;
	${({ theme, haveBg, type }) =>
    haveBg &&
    {
      backgroundColor: type === 'danger' ?
        theme.palette.main.danger :
        '#242C3F'
    }
  }
`;

export const UiSvgIcon = styled(SvgIcon)`
	padding: 2px;
	font-size: 2rem;
	background-color: ${({background})=> background || '#000'};
	border-radius: 100px;
	cursor: pointer;

	> path {
		fill: ${({color})=>color};
	}
`;
