import React from 'react';
import PropTypes from 'prop-types';
import { Icon, ClickableUpload } from 'components';

import { AvatarUploadBox, UiAvatarFrame, UiAvatarImg } from './AvatarUpload.style';


/**
 * 上傳圖片
 * @param {function} onUploaded 上傳完成後，回傳圖片檔名
 */
export const AvatarUpload = ({ imgUrl, onUploaded }) => {

  return (
    <ClickableUpload acceptedFiles={['image/*']} onUploaded={onUploaded}>
      {
        imgUrl ? (
          <UiAvatarFrame>
            <UiAvatarImg url={imgUrl} />
          </UiAvatarFrame>
        ) : (
            <AvatarUploadBox>
              <Icon title="上傳圖片" name="add" />
              <div>點擊上傳圖片</div>
            </AvatarUploadBox>
          )
      }
    </ClickableUpload>
  );
};

AvatarUpload.propTypes = {
  imgUrl: PropTypes.string,
  onUploaded: PropTypes.func,
};


