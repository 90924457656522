import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  IconButton as MatIconButton,
  Avatar as MatAvatar
} from '@material-ui/core';

import { useUser } from 'store/user';
import { ClickablePopoverMenu } from 'components';
import { UiAvatarMenu, UiNickname, UiButtonWrapper } from './AvatarMenu.style';


/**
 * Header 用頭像組件
 */

export const AvatarMenu = () => {
  const history = useHistory();
  const [{ profile, myAllOrganizations }, { userLogout }] = useUser();
  const isHome = useRouteMatch({ path: '/home' }) ? true : false;
  const logoutHandler = () => {
    userLogout();
  };

  const goToAdminPage = () => {
    const orgId = localStorage.getItem('organizationId');
    const choosedOrganization = orgId ? orgId : Object.keys(myAllOrganizations.ownOrganizationsDataMap)[0];
    history.push(`/organization/${choosedOrganization}`);
  };

  const popoverMenuList = () => {
    const list = [
      { label: '登出', func: logoutHandler, iconName: 'exitToApp' }
    ];
    if (isHome && Object.keys(myAllOrganizations.ownOrganizationsDataMap).length > 0) {
      list.splice(2, 0,
        { label: '後台管理', func: goToAdminPage, iconName: 'developerBoard' }
      );
    }
    return list;
  };




  return (
    <UiAvatarMenu>
      {
        profile && profile.id && (
          <ClickablePopoverMenu menuList={popoverMenuList()}>
            <UiButtonWrapper>
              <MatIconButton color="inherit">
                <MatAvatar src={profile.thumbnailUrl || ''} alt={profile.nickname} />
              </MatIconButton>
              <UiNickname>{profile.nickname}</UiNickname>
            </UiButtonWrapper>
          </ClickablePopoverMenu>
        )
      }
    </UiAvatarMenu>
  );
};

AvatarMenu.propTypes = {
  userId: PropTypes.string
};


