import MatButton from '@material-ui/core/Button';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const UiButton = styled(MatButton)`
position: relative;
display: flex;
cursor: ${({ buttoncolor, loading }) => (buttoncolor === 'disable' || loading === true) ? 'not-allowed' : 'pointer'};
padding: 5px 25px;
color:
	${(
		{ theme,
		textColor }
	) => textColor ?
	textColor
	: theme.oneClass.button.text.default};

background:
	${(
		{ theme,
		buttoncolor,
		loading }
	) => loading === true ?
	theme.oneClass.button.background['disable'] :
	buttoncolor === 'danger' ?
	theme.palette.main[buttoncolor] :
	theme.palette.btn[buttoncolor]};
border-radius: 5px;
box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.3);

&:hover {
	background:
		${(
			{ theme,
			buttoncolor,
			loading }
		) => loading === true ?
		theme.oneClass.button.mouseOver.background['grey'] :
		buttoncolor === 'danger' ?
		theme.palette.main.rose :
		theme.palette.btn[buttoncolor + 'hover']};
}
`;
export const UiCircularProgress = styled(CircularProgress)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	color: #fff;
`;
export const UiInlineFlex = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;

	& > svg {
		margin-left: ${({ endIcon }) => endIcon && '5px'};
		font-size: 1.5rem;
	}
`;
