import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';


export const UiBsModal = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 48px 24px 20px;
	max-width: 600px;
	background-color: #fff;
	flex-direction: column;

	> .close {
		position: absolute;
		top: 29px;
		right: 29px;
		width: max-content;
		width: 24px;
		cursor: pointer;
		height: 24px;

		&:hover {
			opacity: 0.8;
		}
	}

	> .title {
		padding: 0 36px;
		font-size: 24px;
		color: #505565;
		margin-bottom: 24px;
	}

	> .content {
		padding: 0 36px;
		font-size: 14px;
		color: #505565;
		margin-bottom: 56px;
		line-height: 32px;
	}

	> .buttonBox {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 36px;
			border-radius: 2px;
			transition: 0.35s;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}

			&.white {
				margin-right: 10px;
				font-size: 16px;
				color: #8b90a0;
				background-color: #fff;
			}

			&.black {
				font-size: 14px;
				color: #fff;
				background-color: #121232;
			}
		}
	}
`;

export const UiCircularProgress = styled(CircularProgress)`
	color: #fff;
`;

export const UiWhiteCircularProgress = styled(CircularProgress)`
	color: #8b90a0;
`;
