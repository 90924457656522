import React,{ useEffect } from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import { Table, Select, IconInput, Button,Icon } from 'components';
import { useHistory,useParams } from 'react-router-dom';
import { useAlert } from 'utils/hooks/useAlert';
import {
  getOrganizationStaffs as getOrganizationStaffsApi,
  deleteOrganizationStaff as deleteOrganizationStaffApi
 } from 'services/api/admin/users';
import {  UiOrganizationTable, UiFilterBox, UiFilterBoxLeft,UiFilterBoxRight } from './OrganizationStaffTable.style';


/**
 * 組織教師
 */

const errorMessage = {
  90052:'無法移除組織管理員',
  90053:'無法移除班級導師',
  20004:'使用者不在組織內',
};

export const OrganizationStaffTable = () => {
  const [{ nowPage, newRowsPage,staffs,total, selectOption, search }, setState] = useSetState({
    nowPage: 0,
    newRowsPage: 10,
    staffs:[],
    total:0,
    selectOption: '',
    search: ''
  });
  const history = useHistory();
  const { setAlert } = useAlert();
  const { organizationId } = useParams();

  const schema = {
    nickname: {
      name: '機構暱稱',
      defaultValue: '',
    },
    originalName: {
      name: '姓名',
      defaultValue: '',
    },
    originalNickname: {
      name: '暱稱',
      defaultValue: '',
    },
    id: {
      name: '帳號ＩＤ',
      defaultValue: '',
    },
    organizationIdentity: {
      name: '身份',
      defaultValue: '',
    },
  };

  const stateShowText = [
    {
      id: 'organizationIdentity',
      state: {
        groupOwner:'導師',
        staff: '教師',
        owner: '機構管理員',
      }
    }
  ];

  // 下拉選單系列
  const selectProps = {
    label: '篩選條件',
    options: [
      {
        name: '教師ID',
        value: 'staffId'
      },
      {
        name: '教師名稱',
        value: 'nameKeyword'
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value, nowPage: 0 });
    }
  };

  // 搜尋系列
  const IconInputProps = {
    placeholder: '搜尋欄位名稱',
    onChange: value => {
      setState({ search: value, nowPage: 0 });
    }
  };

  const fetchOrganizationStaffs = async () => {
    const params = {
      nowPage,
      rowsPage:newRowsPage,
    };
    if(selectOption && search){
      params[selectOption] = search;
    }
    const { data:{ staffs,total } } = await getOrganizationStaffsApi(organizationId,params);
    setState({
      staffs,
      total
    });
  };

  const ActionComponents = ({params}) => {
    const { organizationIdentity,id } = params;
    const deleteStaff = async () => {
      if(organizationIdentity !== 'staff') return;
      const { isSuccess,error } = await deleteOrganizationStaffApi(organizationId,id);
      if(isSuccess){
        await fetchOrganizationStaffs();
        setAlert('移出成功', 'success');
      }else{
        const { errorCode } = error;
        setAlert(errorMessage[errorCode] || '移出失敗', 'success');
      }
    };

    return (
      <>
        <Icon.Svg
          onClick={deleteStaff}
          name='UserMinusSolid'
          color='#fff'
          background={organizationIdentity === 'staff' ? '#000' : '#ccc'}
        />
      </>
    );
  };

  // 更換 每頁顯示
  const changePageRows = ({newPage,newRowsPage}) => {
    setState({ nowPage: newPage, newRowsPage: newRowsPage });
  };

  useEffect(()=>{
    fetchOrganizationStaffs();
  },[nowPage,newRowsPage,search]);

  return (
    <UiOrganizationTable>
      <UiFilterBox>
        <UiFilterBoxLeft>
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
          <IconInput
            placeholder={IconInputProps.placeholder}
            onChange={IconInputProps.onChange}
          />
        </UiFilterBoxLeft>
        <UiFilterBoxRight>
          <Button onClick={() => history.push('/admin/organizations')}>返回機構列表</Button>
        </UiFilterBoxRight>
      </UiFilterBox>
      <Table
        data={staffs}
        schema={schema}
        totalPage={total}
        ActionComponents={ActionComponents}
        changePage_Rows={changePageRows}
        stateShowText={stateShowText}
        nowPage={nowPage}
      />
  </UiOrganizationTable >
  );
};

OrganizationStaffTable.propTypes = {

};


