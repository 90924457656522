import { Api } from 'services/api';
import { stringify } from 'query-string';

/**
 * 取得 所有user資訊
 *
 * @returns {object} all user list
 */
export const getUsers = async (params) => {
  const { nickname, mobileNumber, email, userId, isTeacher, nowPage, rowsPage } = params;

  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (nickname) {
    queryParams.nickname = nickname;
  }

  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }

  if (email) {
    queryParams.email = email;
  }
  if (userId) {
    queryParams.userId = userId;
  }

  if (isTeacher) {
    queryParams.isTeacher = isTeacher;
  }

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/admin/users?${querystring}`);
  return response;
};


/**
 * 取得 所有user資訊
 *
 * @returns {object} all user list
 */
export const getAllUsers = async () => {
  const response = await Api.get('/admin/users/');
  return response;
};

/**
 * 取得專業版會員資訊
 * @param {string} mobileNumber
 * @param {string} email
 * @param {string} userId
 * @param {number} nowPage
 * @param {number} rowsPage
 */

export const getPremiumUsers = async (params) => {
  const { mobileNumber, email, userId, nowPage, rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }

  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }

  if (email) {
    queryParams.email = email;
  }
  if (userId) {
    queryParams.userId = userId;
  }
  const querystring = await stringify(queryParams);
  const response = await Api.get(`/admin/users/premium?${querystring}`);
  return response;
};

/**
 * 修改申請專業版會員的狀態
 */
export const reviseUserPremiumStatus = userId => async payload => {
  const response = await Api.put(`/admin/users/${userId}/premium`, payload);
  return response;
};


/**
 * 更新user 資訊
 * Update User Profile
 * @param {boolean} isTeacher
 */
export const updateUserProfile = userId => async isTeacher => {
  const response = await Api.put(`/admin/users/${userId}`, { isTeacher });
  return response;
};

/**
 * 取得使用者獨立與組織的班級
 * Get User Groups And Organizations
 */
export const getUserGroupsAndOrganizations = async (userId) => {
  const response = await Api.get(`/admin/users/${userId}/groups-and-organizations`);
  return response;
};


/**
 * 取得專業版會員資訊
 * @param {string} mobileNumber
 * @param {string} email
 * @param {string} userId
 * @param {number} nowPage
 * @param {number} rowsPage
 */

 export const getOrganizationStaffs = async (organizationId,params) => {
  const { nowPage, rowsPage,nameKeyword,staffId } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (nameKeyword) {
    queryParams.nameKeyword = nameKeyword;
  }
  if (staffId) {
    queryParams.staffId = staffId;
  }

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/admin/organizations/${organizationId}/staffs?${querystring}`);
  return response;
};

 export const deleteOrganizationStaff = async (organizationId,staffId) => {
  const response = await Api.delete(`/admin/organizations/${organizationId}/staffs/${staffId}`);
  return response;
};



