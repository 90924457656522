import { ROLES } from 'constants/index';

import adminRoutes from 'routes/routesConfig/adminRoutes';
import commonRoutes from 'routes/routesConfig/commonRoutes';



export const getAuthRoutes = role => {
  let authRoutes = [];

  switch (role) {
    case ROLES.ADMIN:
      authRoutes = authRoutes.concat(adminRoutes);
      break;
    default:
      break;
  }

  return authRoutes.concat(commonRoutes);
};
