import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Icon as Iconify } from '@iconify/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { sendRegisterLineNotifyMessage } from 'services/api/admin/organzitions';
import { useAlert } from 'utils/hooks/useAlert';

const useStyles = makeStyles(theme => ({
  lineMsg: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '160px',
    width: '100%',
    '& > .iconBox': {
      width: '28px',
      height: '28px',
      padding: '4px',
      '& svg': {
        color: '#5EB762',
        width: '100%',
        height: '100%',
      }
    }
  },
  lineButton: {
    background: '#F38A04',
    borderRadius: '4px',
    color: '#FFF',
    minWidth: '160px',
    height: '40px',
    padding: '8px 16px',
    '&:hover': {
      background: '#f29f05'
    }
  }
}));

export const ConnectLineButton = ({ isDisabled, isConnected }) => {
  const { organizationId } = useParams();
  const { setAlert } = useAlert();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  /* click 綁定LINE帳號 按鈕 */
  const clickLineButton = async () => {
    setIsLoading(true);
    const { status } = await sendRegisterLineNotifyMessage(organizationId);
    if (status === 'success') {
      setAlert('您已成功發送綁定簡訊！', 'success');
    }
    setIsLoading(false);
  };
  return (
    isConnected ? (
      <Box className={classes.lineMsg}>
        <Box className='iconBox'>
          <Iconify icon="ic:baseline-check-circle" />
        </Box>
        <Box>
          <Typography>成功綁定 LINE帳號</Typography>
        </Box>
      </Box>
    ): (
      <Button
        variant="contained"
        startIcon={isLoading ? <Iconify icon="line-md:loading-loop" /> : <Iconify icon="ri:line-fill" />}
        className={classes.lineButton}
        disabled={isDisabled}
        onClick={clickLineButton}
      >
        <Typography>
          綁定 LINE 帳號
        </Typography>
      </Button>
    )
  );
};

ConnectLineButton.propTypes = {
  isDisabled: PropTypes.bool,
  isConnected: PropTypes.bool,
};