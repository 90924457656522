import styled from 'styled-components';
import { Chip as MatChip } from '@material-ui/core';

export const UiChip = styled(MatChip)`
	padding: 0.5rem;

	& > svg {
		font-size: 1.5rem;
	}
`;
