import libphonenumber from 'google-libphonenumber';

export const phoneNumberValid = (number) => {//+886987654321
  let isValidNumber = false;
  try {
    if (!number) return isValidNumber;

    const hasCountryCode = number.substring(0, 1) === '+';
    // 判斷有國碼的手機號碼（ex. +886）
    if (hasCountryCode) {
      if (number?.length < 6) return isValidNumber;

      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const parseNumber = phoneUtil.parse(number);
      isValidNumber = phoneUtil.isValidNumber(parseNumber);
    }
    // 判斷一般臺灣的手機號碼（ex. 09XX）
    else {
      if (number.substring(0, 2) !== '09') throw Error;
      if (number.length !== 10) throw Error;
      isValidNumber = true;
    }
  } catch (error) {
    return false;
  }
  return isValidNumber;
};
export const emailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
