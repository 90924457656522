import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import { Icon } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import { UiBsModal,UiCircularProgress,UiWhiteCircularProgress } from './BsModal.style';


/**
 * 組織後台彈窗組件
 */

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}
}));


export const BsModal = ({title,open,onOk,onCancel,children,isLoading = false}) => {
  const classes = useStyles();
  const handleClose = () => {
    (onCancel && !isLoading) && onCancel();
  };
  const handleOk = () => {
    (onOk && !isLoading) && onOk();
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      disableAutoFocus
      disableEnforceFocus
    >
      <UiBsModal>
        <div className="close" onClick={handleClose}>
          <Icon name="close" icolor="#a1a4b1" haveBg={false}/>
        </div>
        <div className="title">{title}</div>
        <div className="content">
        {children}
        </div>
        <div className="buttonBox">
          <div
            className="button white"
            onClick={handleClose}
          >
            {isLoading ? <UiWhiteCircularProgress size={18}/> :'取消'}
          </div>
          <div
            className="button black"
            onClick={handleOk}
          >
            {isLoading ? <UiCircularProgress size={18}/> :'確認'}
          </div>
        </div>
      </UiBsModal>
    </Modal>
  );
};

BsModal.propTypes = {
  title:PropTypes.string,
  open:PropTypes.bool,
  onOk:PropTypes.func,
  onCancel:PropTypes.func,
  children:PropTypes.any,
  isLoading:PropTypes.bool,
};


