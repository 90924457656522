import { stringify } from 'query-string';
import { Api } from 'services/api';

export const getOrganizations = async () => {
  const response = await Api.get('/organizations');
  return response;
};

export const getDashboardSummary = organizationId => async category => {
  const querystring = stringify({ category });
  const response = await Api.get(`/organizations/${organizationId}/dashboard/summary?${querystring}`);
  return response;
};


/**
 * 取得 organization information
 * @param {string} organizationId
 *
 * @return {object} organization sign information
 */
export const getOrgInfo = async id => {
  const response = await Api.get(`/organizations/${id}`);
  return response;
};


export const updateOrgInfo = async (id, params = {}) => {
  const response = await Api.put(`/organizations/${id}`, params);
  return response;
};

export const getPublicOrganizations = async params => {
  let queryParams = {};
  const { educationName, cityName, districtName, officialName } = params;

  if (educationName) {
    queryParams.educationName = educationName;
  }

  if (cityName) {
    queryParams.cityName = cityName;
  }

  if (districtName) {
    queryParams.districtName = districtName;
  }

  if (officialName) {
    queryParams.officialName = officialName;
  }

  const querystring = stringify(queryParams);
  const response = await Api.get(`/organizations/public?${querystring}`);

  return response;
};

/**
 * 取得 organization 邀請中列表
 * @param {string} organizationId
 * @param {string} role
 * @return {object} organization sign information
 */

export const getOrganizationInvitations = organizationId => async (params) => {
  const { role, nowPage, rowsPage } = params;

  let queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }

  queryParams.role = role || 'staff';
  const querystring = stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/invitations?${querystring}`);

  return response;
};

/**
 * 取得 organization 科目列表
 * @param {string} organizationId
 * @return {object} organization sign information
 */

export const getOrganizationSubjects = (organizationId,groupId) => async () => {
  const response = await Api.get(`/organizations/${organizationId}/subjects?groupId=${groupId}`);
  return response;
};

/**
 * 取得 organization 科目列表
 * @param {string} organizationId
 */

export const getOrganizationGroupAccess = async (organizationId) => {
  const response = await Api.get(`/organizations/${organizationId}/group-access-controls`);
  return response;
};


