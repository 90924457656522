import React from 'react';
import PropTypes from 'prop-types';

import { Modal, DropzoneUploader, Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

import { UiClickableUpload, UiUploadFileName } from './ClickableUpload.style';
import { isExist } from 'utils/helper';


/**
 * 可使被套用的組件點擊後彈出上傳視窗
 */

const FileList = ({ fileName, onDelete }) => {

  return (
    <UiUploadFileName>
      <span>
        {fileName}
      </span>
      <Icon name="delete" size="small" onClick={onDelete} />
    </UiUploadFileName>
  );
};

FileList.propTypes = {
  fileName: PropTypes.string,
  onDelete: PropTypes.func
};


export const ClickableUpload = ({
  children,
  onUploaded,
  isShowFileList = false,
  fileUrl = '',
  acceptedFiles = [],
  filesLimit = 1
}) => {

  const [{ isOpen, file }, setState] = useSetState({
    isOpen: false,
    file: '',
  });

  const deleteFile = index => () => {
    if(isExist(index)) {
      setState({ file: file.filter((item,i) => i !== index ) });
    } else {
      setState({ file: null });
    }
  };

  const actionButtons = [
    {
      text: '確認',
      func: () => onUploaded(file)
    },
    {
      text: '取消',
      color: 'highlight',
      func: deleteFile
    }
  ];

  const onClickHandler = () => {
    setState({ isOpen: true });
  };

  const getModalStateHandler = state => {
    setState({ isOpen: state });
  };

  const onUploadeHandler = file => {
    setState({
      file: filesLimit > 1 ? file : file[0]
    });
  };

  const contentComponent =
    <DropzoneUploader acceptedFiles={acceptedFiles} onChange={onUploadeHandler} filesLimit={filesLimit} />;


  return (
    <>
      <UiClickableUpload onClick={onClickHandler}>
        {children}
      </UiClickableUpload>
      {
        isShowFileList && file && (
          <>
            {
              Array.isArray(file) ? (
                file.map((item, index) => (
                  <FileList key={item.name} fileName={item.name} onDelete={deleteFile(index)}/>
                ))
              ) : (
                <FileList fileName={file.name} onDelete={deleteFile(null)}/>
              )
            }
          </>
        )
      }
      {
        (fileUrl !== '' && !file) &&
        <UiUploadFileName>
          <a href={fileUrl} target='_blank' rel="noopener noreferrer">查看附件</a>
        </UiUploadFileName>
      }
      <Modal
        isIconShow={false}
        isOpen={isOpen}
        content={contentComponent}
        buttons={actionButtons}
        getModalState={getModalStateHandler}
      />
    </>

  );
};

ClickableUpload.propTypes = {
  children: PropTypes.node,
  onUploaded: PropTypes.func,
  isShowFileList: PropTypes.bool,
  fileUrl: PropTypes.string,
  acceptedFiles: PropTypes.array,
  filesLimit: PropTypes.number
};


