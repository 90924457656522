import { useEffect } from 'react';
import { useUser } from 'store/user';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import {
  getMyClasses as getMyClassesApi,
  inviteUsersToFreeGroup as inviteUsersToFreeGroupApi,
  createFreeGroup as createFreeGroupApi,
  getClassInviteCode as getClassInviteCodeApi,
  joinClassByInvitationCode as joinClassByInvitationCodeApi,
  getClassSummary as getClassSummaryApi,
  getClassBrief as getClassBriefApi,
  updateGroupVisibility as updateGroupVisibilityApi,
  updateGroup as updateGroupApi,
  deleteGroup as deleteGroupApi,
  getGroupSubjects as getGroupSubjectsApi
} from 'services/api/home/class';
import {
  getClasses as getClassesApi,
  getAllClasses as getAllClassesApi,
  createClassInfo as createClassInfoApi,
  editClassInfo as editClassInfoApi,
  removeClassInfo as removeClassInfoApi,
} from 'services/api/organization/class';

import { DASHBOARD_SUMMARY_CATEGORY } from 'constants/index';
import { convertArrayToMap, convertArrayToMapById } from 'utils/array';
import { useParams, useRouteMatch } from 'react-router-dom';

/**
 * @班級常數
 */
const ADD_CLASS_INFO = 'ADD_CLASS_INFO';
const UPDATE_CLASS_INFO = 'UPDATE_CLASS_INFO';
const REMOVE_CLASS_INFO = 'REMOVE_CLASS_INFO';
const GET_CLASS = 'GET_CLASS';
const GET_MY_CLASS = 'GET_MY_CLASS';
const GET_ALL_CLASS = 'GET_ALL_CLASS';
const GET_CLASSS_INFO = 'GET_CLASSS_INFO';
const GET_MY_CLASSS_INFO = 'GET_MY_CLASSS_INFO';
const SET_IS_FREE_GROUP = 'SET_IS_FREE_GROUP';
const CREATE_FREE_GROUP = 'CREATE_FREE_GROUP';
const INVITE_USERS_TO_FREE_GROUP = 'INVITE_USERS_TO_FREE_GROUP';
const GET_CLASS_INVITE_CODE = 'GET_CLASS_INVITE_CODE';
const JOIN_CLASS_BY_INVITATION_CODE = 'JOIN_CLASS_BY_INVITATION_CODE';
const GET_CLASS_SUMMARY = 'GET_CLASS_SUMMARY';
const GET_CLASS_BRIEF = 'GET_CLASS_BRIEF';
const SET_CLASS_VISIBILITY = 'SET_CLASS_VISIBILITY';
const UPDATE_CLASS = 'UPDATE_CLASS';
const DELETE_CLASS = 'DELETE_CLASS';
const GET_CLASS_SUBJECTS = 'GET_CLASS_SUBJECTS';

const initState = {
  isFreeGroup: false,
  classes: {
    data: [],
    dataMap: {},
    dataInfo: {},
    total: 0,
  },
  myClasses: {
    data: [],
    dataMap: {},
    dataInfo: {},
  },
  allClasses: {
    data: [],
    total: 0
  },
  classCode: {
    data: null,
  },
  classBrief: {},
  classSummary: {
    [DASHBOARD_SUMMARY_CATEGORY.GROUP]: null,
    [DASHBOARD_SUMMARY_CATEGORY.USER]: null,
    [DASHBOARD_SUMMARY_CATEGORY.HOSTCANDIDATE]: null,
    [DASHBOARD_SUMMARY_CATEGORY.ANNOUNCEMENT]: null,
    [DASHBOARD_SUMMARY_CATEGORY.MISSION]: null,
    [DASHBOARD_SUMMARY_CATEGORY.POST]: null,
    [DASHBOARD_SUMMARY_CATEGORY.SESSION]: null
  },
  subjects:{
    data:[]
  }
};

const actions = {
  getClass: ({ data, dataMap, total }) => ({
    type: GET_CLASS,
    payload: { data, dataMap, total },
  }),
  getClassInfo: dataInfo => ({
    type: GET_CLASSS_INFO,
    payload: dataInfo
  }),
  addClassInfo: params => ({
    type: ADD_CLASS_INFO,
    payload: params,
  }),
  updateClassInfo: params => ({
    type: UPDATE_CLASS_INFO,
    payload: params,
  }),
  removeClassInfo: id => ({
    type: REMOVE_CLASS_INFO,
    payload: id,
  }),
  getMyClass: data => ({
    type: GET_MY_CLASS,
    payload: { data, dataMap: convertArrayToMapById(data) },
  }),
  getMyClassInfo: dataInfo => ({
    type: GET_MY_CLASSS_INFO,
    payload: dataInfo
  }),
  getAllClass: ({ data, total }) => ({
    type: GET_ALL_CLASS,
    payload: { data, total },
  }),
  setIsFreeGroup: isFreeGroup => ({
    type: SET_IS_FREE_GROUP,
    payload: isFreeGroup
  }),
  createFreeGroup: params => ({
    type: CREATE_FREE_GROUP,
    payload: { params },
  }),
  inviteUsersToFreeGroup: users => ({
    type: INVITE_USERS_TO_FREE_GROUP,
    payload: users
  }),
  getClassInviteCode: data => ({
    type: GET_CLASS_INVITE_CODE,
    payload: { data },
  }),
  joinClassByInvitationCode: code => ({
    type: JOIN_CLASS_BY_INVITATION_CODE,
    payload: code
  }),
  getClassSummary: ({ data, category }) => ({
    type: GET_CLASS_SUMMARY,
    payload: { data, category },
  }),
  getCLassBrief: data => ({
    type: GET_CLASS_BRIEF,
    payload: { data },
  }),
  setClassVisibility: data => ({
    type: SET_CLASS_VISIBILITY,
    payload: { data }
  }),
  updateClass: data => ({//更新班級簡介
    type: UPDATE_CLASS,
    payload: { data }
  }),
  deleteGroup: id => ({
    type: DELETE_CLASS,
    payload: id
  }),
  getSubjects: data => ({
    type:GET_CLASS_SUBJECTS,
    payload: data
  })
};

export const useClass = () => {
  const dispatch = useDispatch();
  const isFreeGroup = Boolean(useRouteMatch({ path: '/home/class' }));
  const {
    classes,
    myClasses,
    allClasses,
    classCode,
    classSummary,
    classBrief,
    subjects
  } = useSelector(state => state.class);
  const { profile } = useSelector(state => state.user);
  const { organizationId, classId } = useParams();
  const [, { getMyAllOrganizations }] = useUser();
  const { setAlert } = useAlert();

  /**
   * 班級列表
   */
  const getClasses = async params => {
    const { data, isSuccess } = await getClassesApi(organizationId)(params ? params : '');
    if (!isSuccess) return;
    const { groups, total } = data;
    const dataMap = convertArrayToMap(groups, 'id');
    dispatch(actions.getClass({ data: groups, dataMap, total }));
  };

  const createClassInfo = async params => {
    const { isSuccess, error } = await createClassInfoApi(organizationId)(params);

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
    }

    dispatch(actions.addClassInfo(params));
    return isSuccess;
  };


  const editClassInfo = async params => {
    const { isSuccess, error } = await editClassInfoApi(organizationId, classId)(params);

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }

    dispatch(actions.updateClassInfo(params));
    return isSuccess;

  };

  const removeClassInfo = async classId => {
    const { isSuccess, error } = await removeClassInfoApi(organizationId, classId)();

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }

    dispatch(actions.removeClassInfo(classId));
    return isSuccess;
  };


  const getAllClasses = async params => {
    if (allClasses.data.length > 0) return allClasses;
    const { groups, total } = await getAllClassesApi(params);
    dispatch(actions.getAllClasses({ data: groups, total }));
    return groups;
  };

  const getMyClasses = async params => {
    const { isSuccess, data } = await getMyClassesApi(params);
    const groups = data.map(item => {
      item.hostCandidates = item.hostCandidates || [];
      item.hosts = item.hosts || [];
      item.users = item.users || [];
      if (item.organizationRole) {
        item.groupRole = item.organizationOwnerId === profile.id ? 'organizationOwner' : item.organizationRole;
      } else {
        if (item.hostCandidates.includes(profile.id) || item.hosts.includes(profile.id)) item.groupRole = 'staff';
        if (item.users.includes(profile.id)) item.groupRole = 'customer';
      }

      if (item.ownerId === profile.id) item.groupRole = 'groupOwner';

      item.userType = (item.groupRole === 'staff' ||
        item.groupRole === 'groupOwner' ||
        item.groupRole === 'organizationOwner') ? 'teacher' : 'student';
      return item;
    });
    if (!isSuccess) return;
    dispatch(actions.getMyClass(groups));
  };

  /**
 *
 * 新建獨立班級
 */
  const createFreeGroup = async payload => {
    const { isSuccess, data, error } = await createFreeGroupApi(payload);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.createFreeGroup(data));
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
    }
    return isSuccess;
  };

  const inviteUsersToFreeGroup = async params => {
    const { isSuccess, error } = await inviteUsersToFreeGroupApi(classId)(params);
    let userError = '';
    dispatch(actions.inviteUsersToFreeGroup(params));

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.INVITE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode, invalidUsers } = error;
      if (errorCode === 30021) {
        // 10001 帳號不存在
        // 30004 成員已存在
        // 30017 成員已邀請
        userError = invalidUsers;
      }
    }

    return { isSuccess, userError };
  };

  /**
   *
   * 取得班級邀請代碼
   */
  const getClassInviteCode = async () => {
    const { isSuccess, data, error } = await getClassInviteCodeApi(classId);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.INVITED_CODE_SUCCESS, 'success');
      dispatch(actions.getClassInviteCode(data));
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.INVITED_CODE_FAIL, 'error', errorCode);
    }
  };

  const joinClassByInvitationCode = async code => {
    const { isSuccess, error } = await joinClassByInvitationCodeApi(code);
    dispatch(actions.joinClassByInvitationCode(code));

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.JOIN_GROUP_SUCCESS, 'success');
      await getMyAllOrganizations(true);
      await getMyClasses();
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.JOIN_GROUP_FAIL, 'error', errorCode);
    }

    return isSuccess;
  };

  //獲取班級簡介
  const getClassBrief = async () => {
    const { isSuccess, data, error } = await getClassBriefApi(classId);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.getCLassBrief(data));
    } catch (error) {
      dispatch(actions.getCLassBrief({}));
    }
  };

  useEffect(() => {
    if (!classId || myClasses.data.length < 1) {
      dispatch(actions.getMyClassInfo({}));
      return;
    }
    const myClassInfo = myClasses.dataMap[classId];

    if (!myClassInfo) return;
    myClassInfo.isOwner = myClassInfo.ownerId === profile.id ? true : false;
    dispatch(actions.getMyClassInfo(myClassInfo));
  }, [classId, myClasses.data]);

  useEffect(() => {
    if (!classId || classes.data.length < 1) {
      dispatch(actions.getClassInfo({}));
      return;
    }
    const classInfo = classes.dataMap[classId];
    if (!classInfo) return;
    dispatch(actions.getClassInfo(classInfo));
  }, [classId, classes.data]);

  useEffect(() => {
    dispatch(actions.setIsFreeGroup(isFreeGroup));
  }, [isFreeGroup]);

  const getClassSummary = async category => {
    const { data } = await getClassSummaryApi(classId)(category);
    dispatch(actions.getClassSummary({ data, category }));
    return data;
  };

  const getDashboardClassSummary = () => getClassSummary(DASHBOARD_SUMMARY_CATEGORY.GROUP);
  const getDashboardUserSummary = () => getClassSummary(DASHBOARD_SUMMARY_CATEGORY.USER);
  const getDashboardHostSummary = () => getClassSummary(DASHBOARD_SUMMARY_CATEGORY.HOSTCANDIDATE);
  const getDashboardAnnouncementSummary = () => getClassSummary(DASHBOARD_SUMMARY_CATEGORY.ANNOUNCEMENT);
  const getDashboardHomeworkSummary = () => getClassSummary(DASHBOARD_SUMMARY_CATEGORY.MISSION);
  const getDashboardPostSummary = () => getClassSummary(DASHBOARD_SUMMARY_CATEGORY.POST);
  const getDashboardSessionSummary = () => getClassSummary(DASHBOARD_SUMMARY_CATEGORY.SESSION);


  const setClassVisibility = async isHidden => {//隱藏班級
    const { isSuccess, data, error } = await updateGroupVisibilityApi(classId)(isHidden);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.setClassVisibility(data));
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
    return isSuccess;
  };

  const updateClass = async params => {//更新班級簡介
    const { isSuccess, error } = await updateGroupApi(classId)(params);
    try {
      if (!isSuccess) throw error;
      await getMyClasses();
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };
  const deleteGroup = async () => {//刪除班級
    const { isSuccess, error } = await deleteGroupApi(classId);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }
    return isSuccess;
  };

  const getSubjects = async classId => {
    const { isSuccess, data,error } = await getGroupSubjectsApi(classId);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.getSubjects(data));
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }
  };

  return [
    {
      classes,
      myClasses,
      allClasses,
      isFreeGroup,
      classCode,
      classSummary,
      classBrief,
      subjects,
    }, // state
    {
      getClasses,
      createClassInfo,
      editClassInfo,
      removeClassInfo,
      getAllClasses,
      getMyClasses,
      createFreeGroup,
      inviteUsersToFreeGroup,
      getClassInviteCode,
      getDashboardClassSummary,
      getDashboardUserSummary,
      getDashboardHostSummary,
      getDashboardAnnouncementSummary,
      getDashboardHomeworkSummary,
      getDashboardPostSummary,
      joinClassByInvitationCode,
      getDashboardSessionSummary,
      getClassBrief,
      setClassVisibility,
      updateClass,
      deleteGroup,
      getSubjects,
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CLASS: {
      const { data, dataMap, total } = action.payload;
      return {
        ...state,
        classes: {
          data,
          dataMap,
          total,
        }
      };
    }
    case GET_MY_CLASS: {
      const { data, dataMap } = action.payload;
      return {
        ...state,
        myClasses: {
          ...state.myClasses,
          data,
          dataMap,
        }
      };
    }
    case GET_CLASSS_INFO: {
      const dataInfo = action.payload;
      return {
        ...state,
        classes: { ...state.classes, dataInfo }
      };
    }
    case GET_MY_CLASSS_INFO: {
      const dataInfo = action.payload;
      return {
        ...state,
        myClasses: { ...state.myClasses, dataInfo }
      };
    }
    case GET_ALL_CLASS: {
      const { data, total } = action.payload;
      return {
        ...state,
        allClasses: {
          data,
          total
        }
      };
    }
    case SET_IS_FREE_GROUP: {
      return {
        ...state,
        isFreeGroup: action.payload
      };
    }
    case GET_CLASS_INVITE_CODE: {
      const { data } = action.payload;
      return {
        ...state,
        myClasses: {
          ...state.myClasses,
          dataInfo: {
            ...state.myClasses.dataInfo,
            invitationCode: data.code,
            invitationCodeExpiredAt: data.expiredAt,
          }
        },
        classCode: {
          data,
        },
      };
    }
    case GET_CLASS_SUMMARY: {
      const { category, data } = action.payload;
      return {
        ...state,
        classSummary: {
          ...state.classSummary,
          [category]: data
        }
      };
    }
    case GET_CLASS_BRIEF: {
      const { data } = action.payload;
      return {
        ...state,
        classBrief: data
      };
    }
    case SET_CLASS_VISIBILITY: {
      const { isHidden } = action.payload.data;
      const nextDataInfo = Object.assign(state.myClasses.dataInfo, { isHidden });
      return {
        ...state,
        myClasses: {
          ...state.myClasses,
          dataInfo: nextDataInfo
        }
      };
    }
    case GET_CLASS_SUBJECTS: {
      const data = action.payload;
      const nextData = data.map(item=>{
        return {
          name:item.name,
          value:item.code
        };
      });
      return {
        ...state,
        subjects: {
          data:nextData
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
