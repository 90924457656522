import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { TextField as MatTextField } from '@material-ui/core';

import { Form, Checkbox, Icon, Modal } from 'components';
import { FORGETPASSWORD, REGISTER } from 'constants/index';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { useDetectionDevice } from 'utils/hooks/useDetectionDevice';
import {
  UiLoginForm,
  UiLoginFormFooter,
  UiContactUs,
  UiTypography,
  UiDivider,
  UiRow,
  UiIconList,
  UiIconRow
} from './LoginForm.style';
/**
 * 登入
 */

const schema = {
  username: {
    component: MatTextField,
    elementProps: {
      label: '帳號',
      defaultValue: '',
      helperText: '',
      variant: 'outlined'
    },
    rules: {
      required: {
        value: true,
        message: '本欄位為必填'
      }
    }
  },
  password: {
    component: MatTextField,
    elementProps: {
      label: '密碼',
      type: 'password',
      value: '',
      helperText: '',
      variant: 'outlined'
    },
    rules: {
      required: {
        value: true,
        message: '本欄位為必填'
      },
      minLength: {
        value: 8,
        message: '最小長度為 8 個字元'
      }
    }
  },
  remenberMe: {
    component: Checkbox,
    elementProps: {
      label: '記住我',
      name: 'remember',
      checkedValue: true,
      checked: true
    }
  }
};

export const LoginForm = () => {
  const [, { userLogin }] = useUser();

  const { getDeviceInfo, getBrowserInfo } = useDetectionDevice();
  const [{ username, isModalOpen, modalText }, setState] = useSetState({
    username: '',
    isModalOpen: false,
    modalText: ''
  });

  const { broswer, version } = getBrowserInfo();
  const device = getDeviceInfo();
  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };
  const modalButtons = [
    {
      text: '確定',
      func: () => {
        getModalStateHandler(false);
      }
    }
  ];
  const getModalText = () => {
    switch (device) {
      case 'iphone':
        if (broswer === 'Safari') {
          if (version < 11) {
            setState({
              modalText: <p>您目前使用的Safari 瀏覽器版本太舊，為了確保您能獲得最佳的使用體驗，請更新Safari 瀏覽器至11.0之後的版本</p>
            });
          }
        } else {
          setState({
            modalText: <p>您目前使用的瀏覽器非Safari 瀏覽器，為了確保您能獲得最佳的使用體驗，請改用Safari 瀏覽器。</p>
          });
        }
        break;
      default:
        if (broswer === 'Chrome') {
          if (version < 60) {
            setState({
              modalText:
                <p>
                  您目前使用的Chrome瀏覽器版本太舊，為了確保您能獲得最佳的使用體驗，請更新Chrome瀏覽器至
                  <a
                    href="https://www.google.com/intl/zh-TW/chrome/?brand=CHBD&gclid=Cj0KCQjwsYb0BRCOARI
                  sAHbLPhHIx-z8Wp9VLKSs4KoW8e1Uf5sNC25U0PpvRprTDZJ0wqBi0RzXhd0aArrmEALw_wcB&gclsrc=aw.ds"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    60之後的版本
                  </a>
                </p>
            });
          }
        } else {
          setState({
            modalText:
              <p>您目前使用的瀏覽器非Chrome瀏覽器，為了確保您能獲得最佳的使用體驗，請改用
              <a
                  href="https://www.google.com/intl/zh-TW/chrome/?brand=CHBD&gclid=Cj0KCQjwsYb0BRCOARI
                sAHbLPhHIx-z8Wp9VLKSs4KoW8e1Uf5sNC25U0PpvRprTDZJ0wqBi0RzXhd0aArrmEALw_wcB&gclsrc=aw.ds"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chrome瀏覽器
                </a>
                。
              </p>
          });
        }
        break;
    }
  };
  useEffect(() => {
    getModalText();
  }, []);
  useEffect(() => {
    if (modalText !== '') {
      getModalStateHandler(true);
    }
  }, [modalText]);
  const submitHandler = formData => {
    userLogin(formData);
  };
  useEffect(() => {
    const account = (localStorage.getItem('account')) === null ? '' : localStorage.getItem('account');
    setState({
      username: account
    });
    schema.username.elementProps.defaultValue = username;
  }, [username]);

  const buttons = process.env.REACT_APP_ONECLASS_PROJECT_ID === 'xiaoxintek' ? [
    {
      text: '登入',
      type: 'submit',
      func: () => { }
    }
  ] : [
      {
        text: '登入',
        type: 'submit',
        func: () => { }
      }
    ];
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        text={modalText}
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      />
      <UiLoginForm>
        <UiTypography variant="h4">OneLink管理後台登入</UiTypography>
        <Form schema={schema} buttons={buttons} onSubmit={submitHandler} />
        <UiLoginFormFooter>
          <NavLink to={`/${FORGETPASSWORD}`}>
            忘記密碼
          </NavLink>
          <span>|</span>
          <NavLink to={`/${REGISTER}`}>
            註冊帳號
          </NavLink>
        </UiLoginFormFooter>
      </UiLoginForm>
      <UiRow>
        <UiContactUs>
          <UiTypography variant="h4">聯繫我們</UiTypography>
          <UiDivider />
          <UiIconList>
            <UiIconRow href="tel:+886-2-26645500,51">
              <Icon name="phone" title="(02) 2664-5500 #520" />
            </UiIconRow>
            <UiIconRow href="mailto:service@oneclass.com.tw">
              <Icon name="email" title="ervice@oneclass.com.tw" />
            </UiIconRow>
            <UiIconRow href="https://cdn.nani.cool/download/%E5%8D%97%E4%B8%80%E9%81%A0%E7%AB%AF%E9%80%A3%E7%B7%9A.exe">
              <Icon name="settingRemote" title="遠端連線" />
            </UiIconRow>
          </UiIconList>
        </UiContactUs>
      </UiRow>
      <UiRow>
        <p>為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為:IE Edge、Google Chrome或Firefox。</p>
        <p>如使用IE瀏覽器瀏覽本網站，可能會發生部分網頁的呈現會有破圖或版面跑版的情況，亦可能導致某些網頁互動功能無法正確顯示與使用。</p>
      </UiRow>
    </>
  );
};

