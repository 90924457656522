import React from 'react';
import { Route, Redirect, useRouteMatch, useParams } from 'react-router-dom';

import { useUser } from 'store/user';

const AuthGuard = ({ children, ...props }) => {
  const [{ profile: { organizations }, myAllOrganizations }] = useUser();

  const { organizationId } = useParams();
  const isAtBackstage = useRouteMatch('/organization/:organizationId');

  if (isAtBackstage) {
    if (!organizations.includes(organizationId)) {
      return <Redirect to="/home" />;
    }

    if (!myAllOrganizations.isLoaded) {
      return <Redirect to="/home" />;
    }

    const { myPermissions } = myAllOrganizations.dataMap[organizationId];
    const { permission } = props;
    if (!myPermissions.includes(permission) && permission) {
      return <Redirect to="/home" />;
    }
  }
  return children;
};

const RouteWithSubRoutes = route => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props =>
        <AuthGuard {...props} {...route}>
          <route.component {...props} {...route} routes={route.routes} />
        </AuthGuard>
      }
    />
  );
};

const RenderRoutes = ({ routes = [] }) => (
  <>
    {
      routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </>
);


export default RenderRoutes;
