import React from 'react';
import { Redirect } from 'react-router-dom';

import { EDIT_STATUS } from 'constants/index';
import {
  AdminPageLayout,
  Organization,
  OrganizationEdit,
  AccountPage,
  AuthorityPage,
  AccountEditPage,
  OrganizationResourcePage,
  OrganizationStaffTable,
} from 'components';


const RedirectToDefault = () => <Redirect to="/admin/organizations" />;

const routes = [
  {
    path: '/admin',
    component: AdminPageLayout,
    routes: [
      {
        path: '/admin/organizations',
        component: Organization,
        exact: true
      },
      {
        path: '/admin/account',
        component: AccountPage,
        exact: true
      },
      {
        path: `/admin/account/${EDIT_STATUS.EDIT}/:userId`,
        component: AccountEditPage,
        exact: true
      },
      {
        path: '/admin/authority',
        component: AuthorityPage,
        exact: true
      },
      {
        path: `/admin/organizations/${EDIT_STATUS.EDIT}/:organizationId`,
        component: OrganizationEdit,
        exact: true,
        editStatus: EDIT_STATUS.EDIT,
        isAdmin: true
      },
      {
        path: `/admin/organizations/${EDIT_STATUS.READ}/:organizationId`,
        component: OrganizationEdit,
        exact: true,
        editStatus: EDIT_STATUS.READ,
        isAdmin: true
      },
      {
        path: `/admin/organizations/${EDIT_STATUS.CREATE}`,
        component: OrganizationEdit,
        exact: true,
        editStatus: EDIT_STATUS.CREATE,
        isAdmin: true
      },
      {
        path: '/admin/organizations/:organizationId/resources',
        component: OrganizationResourcePage,
        exact: true,
      },
      {
        path: '/admin/organizations/:organizationId/staffs',
        component: OrganizationStaffTable,
        exact: true,
      },
      {
        path: '/admin',
        component: RedirectToDefault
      }
    ]
  }
];

export default routes;
