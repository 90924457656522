import React, { useEffect, Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'components';
import { UiForm, UiControllerWrapper, UiButtonWrapper, UiFormTitle } from './Form.style';


/**
 * Form
 */

export const Form = ({
  title = '',
  schema = {},
  buttons = [],
  isLoading = false,
  needHide = false,
  onSubmit = () => { },
  onFormChange = () => { },
  children,
  resetTrigger = false,
  blackList = [],
}) => {
  const { handleSubmit, errors, control, watch, reset } = useForm();
  const formHandler = callBack => {
    callBack && callBack();
  };
  const watchAllFields = watch();
  useEffect(() => {
    if (!resetTrigger) return;
    let newSchema = {};
    Object.entries(schema).forEach(([key, item], index) => {
      newSchema[key] = item.elementProps.defaultValue ? item.elementProps.defaultValue : '';
    });
    reset(newSchema);
  }, [resetTrigger]);
  useEffect(() => {
    onFormChange(watchAllFields);
  }, [watchAllFields]);

  return (
    <UiForm onSubmit={handleSubmit(onSubmit)}>
      {
        title && <UiFormTitle>{title}</UiFormTitle>
      }
      {
        Object.entries(schema).map(([key, item], index) => {
          if (blackList.includes(key)) return null;
          const { component, elementProps, rules, style } = item;
          const nextLabel = rules && rules['required'] ? `${elementProps.label}*` : elementProps.label;
          const isError = errors[key] && errors[key].message ? true : false;
          const nextHelperText = isError ? errors[key].message : elementProps.helperText || '';
          return (
            component ? (
              <>
                {needHide ?
                  <>
                    {!elementProps.needHide &&
                      <UiControllerWrapper key={key} css={style}>
                        <Controller
                          error={isError}
                          as={component}
                          name={key}
                          control={control}
                          rules={rules}
                          fullWidth={true}
                          {...elementProps}
                          label={nextLabel}
                          helperText={nextHelperText}
                        />
                      </UiControllerWrapper>}
                  </> :
                  <UiControllerWrapper key={key} css={style}>
                    <Controller
                      error={isError}
                      as={component}
                      name={key}
                      control={control}
                      rules={rules}
                      fullWidth={true}
                      {...elementProps}
                      label={nextLabel}
                      helperText={nextHelperText}
                    />
                  </UiControllerWrapper>
                }
              </>
            ) : (
                <Fragment key={key}>
                  {
                    Children.toArray(children).filter(el => el.props.name === key).map(el => {
                      const nextHelperText = isError ? errors[key].message : el.props.helperText || '';
                      return (
                        <UiControllerWrapper key={key}>
                          <Controller
                            error={isError}
                            as={el}
                            name={key}
                            control={control}
                            rules={rules}
                            fullWidth={true}
                            {...elementProps}
                            {...el.props}
                            label={nextLabel}
                            helperText={nextHelperText}
                          />
                        </UiControllerWrapper>
                      );
                    }
                    )
                  }
                </Fragment>
              )
          );
        })
      }


      <UiButtonWrapper>
        {
          buttons && buttons.map((button, index) => {
            return (
              <Button
                onClick={() => formHandler(button.func)}
                key={button.text}
                buttonColor={button.color}
                autoFocus={index === 0 && true}
                type={button.type}
                loading={isLoading}
                icon={button.icon}
                disabled={button.disabled}
              >
                {button.text}
              </Button>
            );
          })
        }
      </UiButtonWrapper>
    </UiForm >
  );
};

Form.propTypes = {
  title: PropTypes.string,
  ref: PropTypes.object,
  schema: PropTypes.shape({
    component: PropTypes.element,
    elementProps: PropTypes.object,
    rules: PropTypes.shape({
      required: PropTypes.shape({
        value: PropTypes.bool,
        message: PropTypes.string,
      }),
      maxLength: PropTypes.shape({
        value: PropTypes.number,
        message: PropTypes.string,
      }),
      minLength: PropTypes.shape({
        value: PropTypes.number,
        message: PropTypes.string,
      }),
      max: PropTypes.shape({
        value: PropTypes.number,
        message: PropTypes.string,
      }),
      min: PropTypes.shape({
        value: PropTypes.number,
        message: PropTypes.string,
      }),
      pattern: PropTypes.shape({
        value: PropTypes.instanceOf(RegExp),
        message: PropTypes.string,
      }),
      validate: PropTypes.func
    })
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onFormChange: PropTypes.func,
  buttons: PropTypes.array,
  children: PropTypes.node,
  resetTrigger: PropTypes.bool,
  blackList: PropTypes.array,
  needHide: PropTypes.bool,
};

