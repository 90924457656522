const images = {};

const req = require.context(process.env.REACT_APP_ONECLASS_PROJECT_ID === 'xiaoxintek' ? 
'../assets/xiaoxinImages' : 
'../assets/images'
, false,/\.(png|jpe?g|svg)$/);

req.keys().forEach(key => {
  const imgName = key.replace(/^.+\/([^/]+)\.(png|jpe?g|svg)$/, '$1');
  images[imgName] = req(key);
});


export default name => {
    return images[name];
};

